import * as React from 'react'

import Page from '../components/Page'
import IndexLayout from '../layouts'
import Filter from '../components/filter/Filter'
import { FormattedMessage } from 'react-intl';
import Gallery from '../components/products/Gallery'
import styled from '@emotion/styled'
import { LilaSpot } from '../constants/lilaIcons'
import { fonts } from '../styles/variables'
import TrustpilotWidget from '../components/trustpilot/TrustpilotWidget'



const LilaContainer = styled.div`
  padding-left: 0.63%;
  padding-right: 0.63%;
`

const LilaTitle1 = styled.div`
font-family:${fonts.bcHairLine};
width:220px;
display:flex;
justify-content:flex-end;
padding-right:1.6rem;
ß
`
const LilaTitle2 = styled.div`
font-family:${fonts.bcHairLine};
width:220px;
padding-left:1.6rem;

`

const LilaDeliverySection = styled.div`
:hover{
.cls-1{
fill:var(--hase);
}

.cls-2{
m
}

}
height:62px;
display:flex;
justify-content:center;
align-items:center;
width:100%;
padding-left:10%;
padding-right:10%;
background:var(--bw);

  @media screen and (max-width:860px){
display:none;
  }

`








const Products = ({pathContext: { locale }})=> (
  <IndexLayout locale={locale}>
    <Page>
        <Filter/>
        <LilaContainer>
<Gallery />
        </LilaContainer>
      <LilaDeliverySection> <LilaTitle1><FormattedMessage id={'delivery_3'}/></LilaTitle1><LilaSpot/> <LilaTitle2><FormattedMessage id={'delivery_5'}/></LilaTitle2></LilaDeliverySection>
<TrustpilotWidget locale={locale}/>


    </Page>
  </IndexLayout>
)

export default Products
