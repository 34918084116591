import * as React from 'react'
import styled from '@emotion/styled'
import ZkittlezGalleryThumb from './zkittlez/ZkittlezGalleryThumb'
import AlienHazeGalleryThumb from './alien-haze/AlienHazeGalleryThumb'
import ShGalleryThumb from './sh/ShGalleryThumb'
import ContextConsumer from '../Context'
import StockProvider from '../StockProvider'
import SpaghettiGalleryThumb from './sc/SpaghettiGalleryThumb'
import CharasGalleryThumb from './charas/CharasGalleryThumb'
import SourGalleryThumb from './st/SourGalleryThumb'



const LilaGallery = styled.div`
width:100%;
margin-top: 8rem;



`

const LilaProducts = styled.div`
display:flex;
  flex-wrap: wrap;
  justify-content:center;


  @media screen and (max-width: 797px) {
justify-content:center;

}

`

const H1 = styled.h1`
padding-left: 23.2px;
margin-bottom: 2rem;

  @media screen and (max-width: 707px) {
  width:100%;

padding-left:0px;


}
  @media screen and (max-width: 797px) {

margin-right:2rem;
  display:flex;
justify-content:center;
margin-bottom:4rem;

}


`

const LilaTrustPilot = styled.div`

`

class Gallery extends React.Component {
  constructor(props: any) {
    super(props)
    this.state = {


    }
  }

searchBrand(brand, filterOptions){
if(filterOptions===undefined || filterOptions===null|| filterOptions.length===0 ){
  return true;
}
    if(filterOptions.length>0) {

      for (let o=0;o<filterOptions.length; o++) {
        if (filterOptions[o].value === brand) {
          return true;

        }

      }
    }


    return false;



}

  componentDidMount() {

  }

  render() {
    return(

      <ContextConsumer>
        {({ data, set }) => (
        <LilaGallery addToCart={this.props.addToCart} id={'component'}>
          <StockProvider cartItems={data.cartItems} set={set}/>

          <LilaProducts>


            {this.searchBrand('lilahase',data._lila_filter)&&
            <ZkittlezGalleryThumb items={data.cartItems} addToCart={this.props.addToCart}/>

            }
            {this.searchBrand('berlinerwiese',data._lila_filter)&&
            <ShGalleryThumb items={data.cartItems} addToCart={this.props.addToCart}/>

            }
            {this.searchBrand('tomhemps',data._lila_filter)&&
            <AlienHazeGalleryThumb items={data.cartItems} addToCart={this.props.addToCart}/>

            }
            {this.searchBrand('mamamary',data._lila_filter)&&
            <SpaghettiGalleryThumb items={data.cartItems} addToCart={this.props.addToCart}/>

            }
            {this.searchBrand('trinacriahemp',data._lila_filter)&&
            <CharasGalleryThumb items={data.cartItems} addToCart={this.props.addToCart}/>

            }
            {this.searchBrand('sensitivecbd',data._lila_filter)&&
            <SourGalleryThumb items={data.cartItems} addToCart={this.props.addToCart}/>

            }

          </LilaProducts>


    </LilaGallery>



        )}
      </ContextConsumer>
  )
  }
  }


export default Gallery


//<P><Zoom delay={4200} right cascade>CBD BLÜTEN</Zoom></P>
