import * as React from 'react'
import styled from '@emotion/styled'
import TrustpilotReviews from "@pittica/gatsby-plugin-trustpilot-widget"
import ContextConsumer from '../Context'
import { fonts } from '../../styles/variables'




const LilaTrustPilotLight = styled.div`
display:flex;
opacity:1;
border-top:1px solid var(--bg);

iframe{
font-family:${fonts.bcHairLine} !important;
}



a{
color:var(--txt) !important;;
font-family:${fonts.bcHairLine} !important;

}
padding-top:3%;
padding-bottom:3%;


height:auto;
justify-content:center;

justify-content:center;

align-items:center;
width:100%;

background:var(--bw);



`





const LilaHover = styled.div`
opacity:.5;
transition:all .4s ease;

:hover{
opacity:1;
}
`

class TrustpilotWidget extends React.Component {
  constructor(props: any) {
    super(props)

    this.state = {
theme:'light',
      font:'arial'
    }
    this.vantaRef = React.createRef()

  }



  componentDidMount() {
this.setState({
  font:fonts.bcHairLine
})
  }

  componentWillUnmount() {
  }

  render() {
    return(
      <ContextConsumer>
        {({ data, set }) => (
      <div >
        {!data.dark&&
        <LilaTrustPilotLight >
          <LilaHover>

            <TrustpilotReviews
              language={this.props.locale}
              culture="US"
              theme="light"
              width="100%"
              height="22px"
              style={{fontFamily:this.state.font}}
            />
          </LilaHover>
        </LilaTrustPilotLight>
        }
        {data.dark&&
        <LilaTrustPilotLight >
          <LilaHover>

            <TrustpilotReviews
              language={this.props.locale}
              culture="US"
              theme="dark"
              width="100%"
              height="22px"
              style={{fontFamily:this.state.font}}            />
          </LilaHover>
        </LilaTrustPilotLight>
        }






      </div>
        )}
      </ContextConsumer>


    )
  }
}


export default TrustpilotWidget


//<P><Zoom delay={4200} right cascade>CBD BLÜTEN</Zoom></P>
