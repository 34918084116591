import * as React from 'react'
import styled from '@emotion/styled'
import { FormattedMessage } from 'react-intl';
import ThIcon1 from '../products/alien-haze/ThIcon1'
import BwIcon1 from '../products/sh/BwIcon1'
import BwIcon2 from '../products/sh/BwIcon2'
import { fonts } from '../../styles/variables'
import { ElementConfig } from 'react'
import { BsChevronCompactDown } from 'react-icons/bs'
import Select, {components} from 'react-select'
import chroma from 'chroma-js';
import MamaMaryLight from '../products/sc/MamaMaryLight'
import MamaMaryDark from '../products/sc/MamaMaryDark'
import SensitiveLight from '../products/st/SensitiveLight'
import SensitiveDark from '../products/st/SensitiveDark'
import TrinacriaHempImg from '../products/charas/TrinacriaHemp'
import ThIcon2 from '../products/alien-haze/ThIcon2'

const LilaArrowWrap = styled.div`
width:54px;
display:flex;
justify-content:center;
align-items:center;
padding-right:3.26%;
`


const LilaHase = styled.div`
display:flex;
align-items:center;
padding-right:7px;
height:30px;

.cls-1{fill:var(--hase);}.cls-2{fill:none;}.cls-3{fill:var(--hase);}


svg{
width:102px;
}

`

const LilaSymbol_2 = styled.div`
  .clss-1{
  fill:var(--hase);
  }
#Layer_5{
width:1.46rem !important;}
.rotate {
  animation: rotation 8s infinite linear;

}

@keyframes rotation {
  from {
    transform: rotate(0deg) ;
  }
  to {
    transform: rotate(359deg) ;
  }
}

display:flex;
margin-left:6%;

justify-content:center;
align-items:center;
  @keyframes rotate{

    100%{
      transform:rotate(360deg);
    }
  }


`


const LilaLight = styled.div`
display:var(--light);
padding-top:2.2px;

`

const LilaDark = styled.div`
display:var(--dark);
padding-top:2.2px;
`


const Lh = styled.div`
width:102px;
//margin-right:0.2rem;
  padding-left: 0.63%;
  padding-right: 0.63%;
align-items:center;
display:flex;

.cls-1{fill:var(--hase);}.cls-2{fill:none;}.cls-3{fill:var(--hase);}
`

const BerlinerWiese = styled.div`
display:flex;
align-items:center;
padding-top:3px;
font-family: "Georgia,Times,&quot;Times New Roman&quot;,serif", sans-serif;
font-weight: 300 !important;
line-height:1 !important;
font-size:1rem;
height:30px;
color:var(--berlinerWiese);


`
const MamaMary = styled.div`
display:flex;
align-items:center;
padding-bottom:0.4rem;
height:30px;



`

const TrinacriaHemp = styled.div`
display:flex;
align-items:center;
//padding-bottom:0.4rem;
height:30px;



`
const SensitiveCBD = styled.div`
display:flex;
align-items:center;
//padding-bottom:0.4rem;
height:50px;



`
const Bw = styled.div`
display:flex;
font-size:0.9rem;
align-items:center;
font-family: "Georgia,Times,&quot;Times New Roman&quot;,serif", sans-serif;
font-weight: 300 !important;
line-height:1 !important;
`





const DropdownIndicator = (
  props: ElementConfig<typeof components.DropdownIndicator>

) => {
  return (
    <components.DropdownIndicator {...props}>
      <LilaArrowWrap><BsChevronCompactDown /></LilaArrowWrap>

    </components.DropdownIndicator>
  );
};

const colourOptions = [
  { id:0,value: 'lilahase', color:'#d7b1d3', label: <LilaHase>
      <svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 372.33 45.05">
        <defs>
        </defs>
        <path className="cls-1" d="M14.76,21.35h7.85V56.9h24.1v7H14.76Z" transform="translate(-14.76 -19.7)"/>
        <path className="cls-1" d="M58.07,21.35h7.85v42.5H58.07Z" transform="translate(-14.76 -19.7)"/>
        <path className="cls-1" d="M80.53,21.35h7.85V56.9h24.1v7H80.53Z" transform="translate(-14.76 -19.7)"/>
        <path className="cls-1" d="M239.11,63.85h-7.85V46.1H207.51V63.85h-7.85V21.35h7.85V39.1h23.75V21.35h7.85Z"
              transform="translate(-14.76 -19.7)"/>
        <path className="cls-1"
              d="M331.8,29a18,18,0,0,0-7.42-1.55c-3,0-5.3.54-6.78,1.6a4.78,4.78,0,0,0-2.22,4,3.25,3.25,0,0,0,1.32,2.75,9,9,0,0,0,3.35,1.5q2,.51,5.58,1.05a64,64,0,0,1,8.85,1.93A13.43,13.43,0,0,1,340.43,44c1.66,1.78,2.5,4.29,2.5,7.52q0,6.51-5,9.85t-13.57,3.35A30.45,30.45,0,0,1,313.5,63a25.28,25.28,0,0,1-7.57-4.25l3.45-6.3a22,22,0,0,0,6.77,3.8,24.17,24.17,0,0,0,8.38,1.45q5.19,0,7.7-1.65a4.94,4.94,0,0,0,2.5-4.25,3.55,3.55,0,0,0-1.38-3,9.44,9.44,0,0,0-3.4-1.63q-2-.54-5.82-1.2a63.73,63.73,0,0,1-8.58-1.9,13.1,13.1,0,0,1-5.77-3.62,10.13,10.13,0,0,1-2.4-7.18,11.27,11.27,0,0,1,2.12-6.85,13.2,13.2,0,0,1,6-4.42,24.23,24.23,0,0,1,9-1.53,23.67,23.67,0,0,1,9.77,1.93,26.54,26.54,0,0,1,7.13,4.47l-3.35,6A26,26,0,0,0,331.8,29Z"
              transform="translate(-14.76 -19.7)"/>
        <path className="cls-1" d="M386.09,39.2V46H363V56.9h24.1v7H355.14V21.35h31.95v7H363V39.2Z"
              transform="translate(-14.76 -19.7)"/>
        <polygon className="cls-2" points="129.98 14.85 122.23 28.85 137.78 28.85 129.98 14.85"/>
        <polygon className="cls-3"
                 points="130.03 0 105.03 44.15 113.68 44.15 118.68 35.1 122.23 28.85 129.98 14.85 137.78 28.85 141.33 35.1 146.38 44.15 155.03 44.15 130.03 0"/>
        <polygon className="cls-2" points="259.86 14.85 252.11 28.85 267.66 28.85 259.86 14.85"/>
        <polygon className="cls-3"
                 points="259.91 0 234.91 44.15 243.56 44.15 248.56 35.1 252.11 28.85 259.86 14.85 267.66 28.85 271.21 35.1 276.26 44.15 284.91 44.15 259.91 0"/>
      </svg>



      <LilaSymbol_2>
        <svg  className="rotate" id="Layer_5" xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 241.36 179.42">
          <defs>
          </defs>
          <path className="clss-1"
                d="M612.62,378.23,762.71,495,536.84,509.45l75.78-131.22m-1-5.15-81,140.22L772,497.82,611.62,373.08Z"
                transform="translate(-530.64 -373.08)"/>
          <path className="clss-1"
                d="M612.62,377l41.91,11.84,55.64,15.73,57.17,92.32-75.55,52L554.7,516.38,535,510.93l25.64-82.21,52-51.77m-1-3.87-54,53.8-27,86.42,23.21,6.42L692.49,552.5,772,497.82l-59.6-96.25-56.93-16.09-43.85-12.4Z"
                transform="translate(-530.64 -373.08)"/>
        </svg>
      </LilaSymbol_2>
     </LilaHase>},
  { id:1,value: 'tomhemps', color:'lightgrey', label: <div style={{paddingRight:'7px', height:'30px', display:'flex',alignItems:'center'}}>            <LilaDark><ThIcon1/></LilaDark>
      <LilaLight><ThIcon2/></LilaLight></div> },
  { id:2,value: 'berlinerwiese',color:'#9bbdba', label:
      <BerlinerWiese>
        <FormattedMessage id={'bw'}/>
        <LilaDark><BwIcon1/></LilaDark>
        <LilaLight><BwIcon2/></LilaLight>

      </BerlinerWiese>

  },  { id:3,value: 'mamamary',color:'darkgrey', label:
      <MamaMary>
        <MamaMaryLight/>
        <MamaMaryDark/>

      </MamaMary>

  }, { id:4,value: 'trinacriahemp',color:'#6ebc44', label:
      <TrinacriaHemp>
        <TrinacriaHempImg/>

      </TrinacriaHemp>

  }, { id:5,value: 'sensitivecbd',color:'#103944', label:
      <SensitiveCBD>
        <SensitiveLight/>
        <SensitiveDark/>

      </SensitiveCBD>

  }
]

const colourStyles = {

  dropdownIndicator: (base, state) => ({
    ...base,
    transition: 'all .2s ease',
    transform: state.isFocused ? 'rotate(180deg)' : null
  }),
  indicatorSeparator: (styles, state) => {
    return {
      ...styles,
      marginBottom: '10px',
      marginTop:'10px',
      opacity:!state.isFocused ? .3: .2,
      color:'var(--txt)'

    };
  },

  control: (styles, state) => ({
    ...styles,
    '&::after': { borderColor: 'var(--hase)' },
    '&:hover': { borderColor: 'none', backgroundColor:'rgba(0, 0, 0, 0.06)' },
    borderColor:!state.isFocused ?'var(--border)':'var(--hase)',
    boxShadow: 'none',
    borderWidth:!state.isFocused ?'1px':'2px',
    borderTop:!state.isFocused ?'1px':'0px',
    borderLeft:!state.isFocused ?'1px':'0px',
    borderRight:!state.isFocused ?'1px':'0px',
    background:'rgba(0, 0, 0, 0.03)',
    color:'var(--txt) !important',
    borderTopLeftRadius:'4px',
    borderTopRightRadius:'4px',
    borderBottomRightRadius:'0px',
    borderBottomLeftRadius:'0px'}),
  singleValue: styles => ({ ...styles,display:'flex', alignItems:'center', backgroundColor: 'none',

    color:'var(--txt) !important' }),
  placeholder: styles => ({ ...styles, fontFamily: fonts.bcHairLine,

    color:'var(--txt) !important' }),
  menu: styles => ({ ...styles, background: 'var(--bg)', marginTop:'0px'
 }),
  indicatorsContainer: styles => ({ ...styles, paddingRight:'3.26%'}),

  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      display:'flex',
      alignItems:'center',

      backgroundColor: isDisabled
        ? 'var(--bg)'
        : isSelected ? 'var(--bw)' : isFocused ? 'var(--bw)' : 'var(--bg)',
      color: isDisabled
        ? 'var(--txt)'
        : isSelected
          ? chroma.contrast(color, 'white') > 2 ? 'white' : 'black'
          : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',
      paddingLeft:'3.26%'
    };
  },
  valueContainer: (styles) => {
    return {
      ...styles,
      paddingLeft: '3.26%'
    };
  },
  multiValue: (styles, { data }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: color.alpha(0.1).css(),
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    ':hover': {
      backgroundColor: data.color,
      color: 'white',
    },
  }),


};


const LilaFilterSelect = styled.div`


`

const StyledSelect = styled(Select)`
width:100%;
position:relative;
z-index:99999999999;

height:auto;
      font-size:calc(18px + (18 - 18) * ((100vw - 300px) / (1600 - 300)));

.css-26l3qy-menu{
border-radius:1px;
}
`


class FilterSelect extends React.Component {
  constructor(props: any) {
    super(props)
    this.state = {

    }
  }



  componentDidMount() {

  }

  render() {
    return(


        <LilaFilterSelect>
          <StyledSelect
            name="brands[]" multiple
            required={true}
            closeMenuOnSelect={true}
            isMulti
            placeholder={<FormattedMessage id="filter_1" />}
            options={colourOptions}
            value={this.props._lila_filter}
            styles={colourStyles}
            components={{ DropdownIndicator }}
            onChange={(selection)=>this.props.filter(selection, this.props.set)}

          ></StyledSelect>


    </LilaFilterSelect>



    )
  }
}


export default FilterSelect


//<P><Zoom delay={4200} right cascade>CBD BLÜTEN</Zoom></P>
