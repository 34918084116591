import * as React from 'react'
import styled from '@emotion/styled'
import { getQuantity, getRequestData, readBulkedInventory, setBulkedInventory } from '../functions/stockFunctions'
import { store } from 'react-notifications-component';

const LilaStockProvider = styled.div`
opacity:0;
width:0px;
height:0px;
`


class StockProvider extends React.Component {
  constructor(props: any) {
    super(props)
    this.state = {

    }
  }



 async componentDidMount() {
 let cartItems = this.props.cartItems
  readBulkedInventory(cartItems).then(function(inventory) {


   const data = inventory.data.Responses.inventory

   for(let c =0;c<cartItems.length;c++){
     for(let d=0;d<data.length;d++){
       if(cartItems[c].item===data[d].id.S){
         cartItems[c].stock=Number(data[d].quantity.N)
         //console.log('match')

if(cartItems[c].quantity>0 && Number(data[d].quantity.N)<cartItems[c].quantity){
  store.addNotification({
    title: cartItems[c].title + ' ' + cartItems[c].subTitle,
    message: "Leider ist das Produkt nicht mehr in der ausgewählten Anzahl erhältlich",
    type: "info",
    insert: "right",
    container: "top-right",
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"],
    dismiss: {
      duration: 5000,
      onScreen: true
    }
  });
//  console.log(cartItems[c].title+ ' nicht mehr erhätlich')
  cartItems[c].quantity=0
}
       }
     }

   }
   //console.log('dope')
  // console.log(await cartItems)
   this.props.set({cartItems: cartItems})



  }.bind(this))
  }

  render() {
    return(


        <LilaStockProvider id={'component'}>



    </LilaStockProvider>



    )
  }
}


export default StockProvider


//<P><Zoom delay={4200} right cascade>CBD BLÜTEN</Zoom></P>
