import styled from '@emotion/styled'
import * as React from 'react'
import 'react-slidedown/lib/slidedown.css'
import Headroom from 'react-headroom'
import { fonts } from '../../styles/variables'
import ContextConsumer from '../Context'
import FilterSelect from './FilterSelect'





const LilaBackground = styled.div`
background:var(--filter);
padding-left:1.26%;
padding-right:1.26%;

`
const LilaFilter = styled.div`



width:100%;

  margin-bottom:2.8rem;
background:var(--bg);

z-index:9999999;
font-family:${fonts.bcExtraLight};


   @media screen and (max-width:960px){
margin-bottom:0.8rem;  }

`


const HeadroomWrapper = styled.div`
position:absolute;
z-index:999;
width:100%;
background:none;



.headroom {
  top: 0;
  left: 0;
  right: 0;
  zIndex: 1;
  margin-top:82px;

   @media screen and (max-width:960px){
margin-top:52px;  }


}

.headroom--unfixed {
  position: relative;
  transform: translateY(0);

}
.headroom--scrolled {
  transition: transform 200ms ease-in-out;
}
.headroom--unpinned {
  position: fixed;
  transform: translateY(-100%);
}
.headroom--pinned {
  position: fixed;
  transform: translateY(0%);

}
`





class Filter extends React.Component {
  constructor(props: any) {
    super(props)
    this.state = {
filterOpen:false,
      _lila_filter:[]
      }

    this.toggleFilter=this.toggleFilter.bind(this)
    this.filter=this.filter.bind(this)

  }


  toggleFilter(){
    this.setState({
      filterOpen:!this.state.filterOpen
    })
  }




  filter(selection, set){
    console.log(selection)

  this.setState({_lila_filter:selection})
    set({_lila_filter:selection})
  }




  componentDidMount() {



/*
    this.setState({
      _lila_filter: isItem('_lila_filter')?JSON.parse(window.localStorage._lila_filter):[]



  */
  }

  render() {
    return(

<HeadroomWrapper>
<Headroom pinStart={0} top={'82px'}>
  <ContextConsumer>
    {({ data, set }) => (
  <LilaFilter id={'component'}>

          <LilaBackground>

<FilterSelect _lila_filter={data._lila_filter} set={set} filter={this.filter}/>

          </LilaBackground>
    </LilaFilter>
    )}
  </ContextConsumer>
</Headroom>
</HeadroomWrapper>

  )
  }
  }


export default Filter


//<P><Zoom delay={4200} right cascade>CBD BLÜTEN</Zoom></P>
